import Typography from "../../Typography";
import {
  ModalRegisterContainer,
  RegisterSection,
  ChooseCharacterSection,
  RegisterForm,
  Group,
  Label,
  StyledInput,
  TermsText,
  AlreadyHaveAccount,
} from "./styled";

import ButtonYellow from "../../ButtonYellow";

function ModalRegister({ setModalRegisterIsOpen, setModalLoginIsOpen }) {
  const handleRegister = (e) => {
    e.preventDefault();
    setModalRegisterIsOpen(false);
  };
  const handleAlreadyHaveAccount = () => {
    setModalRegisterIsOpen(false);
    setModalLoginIsOpen(true);
  };
  return (
    <ModalRegisterContainer>
      <RegisterSection>
        <Typography variant="h1">Register</Typography>
        <RegisterForm>
          <Group>
            <StyledInput
              type="email"
              placeholder="Email"
              name="email"
              required
              autoComplete="new-password"
            />
          </Group>
          <Group>
            <StyledInput
              type="password"
              placeholder="Password"
              name="password"
              required
              autoComplete="new-password"
            />
          </Group>
          <Group>
            <StyledInput
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              required
              autoComplete="new-password"
            />
          </Group>
          <Group variant="inline">
            <Label>Date of birth</Label>
            <StyledInput
              type="text"
              placeholder="dd"
              name="birth_day"
              required
              autoComplete="new-password"
              variant="inline"
            />
            <StyledInput
              type="text"
              placeholder="mm"
              name="birth_month"
              required
              autoComplete="new-password"
              variant="inline"
            />
            <StyledInput
              type="text"
              placeholder="yyyy"
              name="birth_year"
              required
              autoComplete="new-password"
              variant="inline"
            />
          </Group>
          <Group variant="inline">
            <StyledInput
              type="checkbox"
              name="confirm_age"
              required
              style={{ marginRight: "20px", width: "10px" }}
            />
            <Label>I am over 18 or have parental consent</Label>
          </Group>
          <ButtonYellow onClick={handleRegister}>
            Begin your adventure
          </ButtonYellow>
          <TermsText>
            By creating an account, I accept BetQuest's Terms of Service and
            Privacy Policy
          </TermsText>
        </RegisterForm>
        <AlreadyHaveAccount onClick={handleAlreadyHaveAccount}>
          <p>Have an account?</p>
          <button>Log In.</button>
        </AlreadyHaveAccount>
      </RegisterSection>
      <ChooseCharacterSection></ChooseCharacterSection>
    </ModalRegisterContainer>
  );
}

export default ModalRegister;
