import { useDispatch } from "react-redux";

import { slipRemoveEvent, slipAddEvent } from "../../../../store/slip/actions";

import {
  BetButtons,
  Card,
  BetButton,
  GameLogo,
  GameName,
  GameTournamentInfo,
  StreamIcon,
  StreamLink,
  SwordsContainer,
  SwordsImage,
  TeamLogo,
  TeamLogoContainer,
  TeamsLogos,
  TeamName,
  TeamNames,
  TournamentDate,
  TournamentName,
} from "./styled";

import NoImage from "../../../../assets/game/no_image.png";

function EventItem({ eventDto, eventDtoIndex, inSlip }) {
  const dispatch = useDispatch();
  const handleOnClickBet = (teamSide, inSlip) => {
    if (inSlip && inSlip.teamNumber === 1 && teamSide === "first") {
      dispatch(slipRemoveEvent(inSlip.eventId));
    } else if (inSlip && inSlip.teamNumber === 2 && teamSide === "second") {
      dispatch(slipRemoveEvent(inSlip.eventId));
    } else {
      dispatch(
        slipAddEvent(eventDto.id, teamSide === "first" ? 1 : 2, eventDtoIndex)
      );
    }
  };

  return (
    <Card>
      <BetButtons>
        <BetButton
          side={"left"}
          disabled={!eventDto.team1Winner}
          selected={inSlip && inSlip.teamNumber === 1}
          onClick={
            eventDto.team1Winner
              ? () => handleOnClickBet("first", inSlip)
              : null
          }
        >
          {eventDto.team1Winner || "n/a"}
        </BetButton>
        <BetButton
          side={"right"}
          disabled={!eventDto.team2Winner}
          selected={inSlip && inSlip.teamNumber === 2}
          onClick={
            eventDto.team2Winner
              ? () => handleOnClickBet("second", inSlip)
              : null
          }
        >
          {eventDto.team2Winner || "n/a"}
        </BetButton>
      </BetButtons>
      <TeamsLogos>
        <TeamLogoContainer>
          <TeamLogo src={eventDto.team1LogoUrl || NoImage} />
        </TeamLogoContainer>
        <SwordsContainer>
          <SwordsImage active={inSlip} />
        </SwordsContainer>
        <TeamLogoContainer>
          <TeamLogo src={eventDto.team2LogoUrl || NoImage} />
        </TeamLogoContainer>
      </TeamsLogos>
      <TeamNames>
        <TeamName>
          <div>{eventDto.team1Name || "n/a"}</div>
        </TeamName>
        <TeamName>
          <div>{eventDto.team2Name || "n/a"}</div>
        </TeamName>
      </TeamNames>
      <GameTournamentInfo>
        <GameLogo src={eventDto.categoryLogoUrl || NoImage} />
        <GameName>{eventDto.categoryName}</GameName>
        <TournamentName>{eventDto.tournamentName}</TournamentName>
        <TournamentDate>{eventDto.startDateFormatted}</TournamentDate>
        {eventDto.videoUrl && (
          <StreamLink href={eventDto.videoUrl}>
            <StreamIcon />
          </StreamLink>
        )}
      </GameTournamentInfo>
    </Card>
  );
}

export default EventItem;
