import styled from "styled-components";

import BackgroundModalTopImage from "../../assets/preface/modal_top.png";
import BackgroundModalBodyImage from "../../assets/preface/modal_body.png";
import BackgroundModalBottomImage from "../../assets/preface/modal_bottom.png";
import BackgroundModalTopImageSmall from "../../assets/preface/modal_top_small.png";
import BackgroundModalBodyImageSmall from "../../assets/preface/modal_body_small.png";
import BackgroundModalBottomImageSmall from "../../assets/preface/modal_bottom_small.png";

import ButtonCloseImage from "../../assets/preface/button_cancel.png";

export const Overlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 100;
`;

export const ModalBox = styled.div`
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  width: ${({ variant }) => (variant === "small" ? "850px" : "1086px")};
  height: auto;
  margin: auto;
`;

export const ButtonModalClose = styled.button`
  background-image: url(${ButtonCloseImage});
  background-size: cover;
  width: 46px;
  height: 46px;
  position: absolute;
  top: ${({ variant }) => (variant === "small" ? "50px" : "65px")};
  right: 65px;
  z-index: 11;
`;

export const Content = styled.div`
  padding: ${({ variant }) =>
    variant === "small" ? "50px 100px 80px" : "50px 100px 80px"};
  min-height: 200px;
  z-index: 1;
`;

export const ModalBackgroundTop = styled.img.attrs(({ variant }) => ({
  src:
    variant === "small"
      ? BackgroundModalTopImageSmall
      : BackgroundModalTopImage,
}))`
  width: 100%;
  height: ${({ variant }) => (variant === "small" ? "-" : "60px")};
  position: absolute;
  top: 0;
`;

export const ModalBackgroundBody = styled.img.attrs(({ variant }) => ({
  src:
    variant === "small"
      ? BackgroundModalBodyImageSmall
      : BackgroundModalBodyImage,
}))`
  width: 100%;
  height: calc(100% - 120px);
  position: absolute;
  top: 60px;
`;

export const ModalBackgroundBottom = styled.img.attrs(({ variant }) => ({
  src:
    variant === "small"
      ? BackgroundModalBottomImageSmall
      : BackgroundModalBottomImage,
}))`
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0;
`;
