import {
  SLIP_ADD_EVENT,
  SLIP_REMOVE_EVENT,
  SLIP_UPDATE_STAKE,
  SLIP_UPDATE_COEF,
  SLIP_DIALOG_CLEAN_YOUR_PICKS,
  SLIP_CLEAN,
} from "./types";

//
// ACTION CREATOR slipAddEvent
//
export const slipAddEvent =
  (eventId, teamNumber, eventDtoIdx) => async (dispatch, getState) => {
    const stateBefore = getState();

    const existingEventInSlip = stateBefore.slip.slip.find(
      (e) => e.eventId === eventId
    );
    if (existingEventInSlip) {
      if (existingEventInSlip.teamNumber !== teamNumber) {
        dispatch({
          type: SLIP_REMOVE_EVENT,
          payload: eventId,
        });
      } else {
        return;
        // This should not happen. Instead, click on already actyve bet button
        // will fire slipRemoveEvent
      }
    }
    // no event in slip (no any odd of event in the slip),
    // so add event with the selecetd odd (selected teamNumber)
    dispatch({
      type: SLIP_ADD_EVENT,
      payload: { eventId, teamNumber, eventDtoIdx },
    });

    dispatch(slipUpdateCoef());
  };

export const slipRemoveEvent = (eventId) => async (dispatch) => {
  dispatch({
    type: SLIP_REMOVE_EVENT,
    payload: eventId,
  });

  dispatch(slipUpdateCoef());
};

export const slipUpdateCoef = () => async (dispatch, getState) => {
  const stateBefore = getState();

  const coef = stateBefore.slip.slip
    .map((el) =>
      el.teamNumber === 1
        ? stateBefore.events.list[el.eventDtoIdx].team1Winner
        : stateBefore.events.list[el.eventDtoIdx].team2Winner
    )
    .reduce((acc = 1, cur) => acc * cur, 1);

  const payload = {
    coef,
    winning: coef * parseFloat(stateBefore.slip.stake),
  };

  dispatch({
    type: SLIP_UPDATE_COEF,
    payload,
  });
};

export const slipUpdateStake = (stake) => async (dispatch, getState) => {
  const stateBefore = getState();

  if (parseFloat(stake) > stateBefore.slip.accountBalance) return;

  const payload = {
    stake: stake,
    winning: stateBefore.slip.coef * parseFloat(stake),
  };

  dispatch({ type: SLIP_UPDATE_STAKE, payload });
};

export const dialogCleanYourPicks = (show) => ({
  type: SLIP_DIALOG_CLEAN_YOUR_PICKS,
  payload: show,
});

export const slipClean = () => ({
  type: SLIP_CLEAN,
});
