import styled from "styled-components";

import ButtonHelpImage from "../../../assets/game/button_help.png";
import ButtonChatImage from "../../../assets/game/button_chat.png";

export const FooterWrapper = styled.footer`
  margin-top: -103px;
  z-index: 10;
`;

export const HelpAndChatList = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 68px;
  height: 103px;
  z-index: 1;

  li:last-child {
    margin: 0 0 0 10px;
  }

  button {
    transition: all 0.5s;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const HelpButton = styled.button`
  background-image: url(${ButtonHelpImage});
  width: 59px;
  height: 59px;
`;
export const ChatButton = styled.button`
  background-image: url(${ButtonChatImage});
  width: 59px;
  height: 59px;
`;
