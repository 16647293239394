import styled from "styled-components";

import BoardImage from "../../../assets/game/event_board.png";
import TabLargeActiveImage from "../../../assets/game/event_board_tablargeactive.png";
import TabLargeNotActiveImage from "../../../assets/game/event_board_tablargenotactive.png";
import TabSmallActiveImage from "../../../assets/game/event_board_tabsmallactive.png";
import TabSmallNotActiveImage from "../../../assets/game/event_board_tabsmallnotactive.png";

export const EventBoardWrapper = styled.div`
  position: relative;
  width: auto;
  height: 911px;
  margin: 0 5px 0 0px;
`;

export const BoardBackgroundImage = styled.img.attrs({
  src: BoardImage,
  alt: "Board Image",
})`
  height: 911px;

  @media (max-width: 950px) {
    width: 470px;
  }
`;

export const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Menu = styled.div`
  height: 80px;
`;

export const TabList = styled.ul`
  display: flex;
  &:first-child {
    margin-left: 15px;
  }
`;

export const TabSmall = styled.li`
  background-image: ${({ active }) =>
    active ? `url(${TabSmallActiveImage})` : `url(${TabSmallNotActiveImage})`};
  width: 135px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  color: white;
  cursor: pointer;
  z-index: ${({ active }) => (active ? `1` : `0`)};

  span {
    color: ${({ active }) => (active ? "white" : "#b59a9d")};
    text-transform: uppercase;
  }
`;

export const TabLarge = styled.li`
  background-image: ${({ active }) =>
    active ? `url(${TabLargeActiveImage})` : `url(${TabLargeNotActiveImage})`};
  width: 216px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -15px;
  color: white;
  cursor: pointer;
  z-index: ${({ active }) => (active ? `1` : `0`)};

  span {
    color: ${({ active }) => (active ? "white" : "#b59a9d")};
    text-transform: uppercase;
  }
`;

export const EventFilters = styled.div``;

export const EventListGrid = styled.ul`
  display: grid;
  padding: 0 100px;
  margin-right: 20px;
  grid-template-columns: repeat(auto-fit, minmax(242px, 1fr));
  gap: 15px;
  max-height: 740px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-items: center;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: none;
  }
`;
