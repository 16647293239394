import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { theme } from "./styles/theme";
import { GlobalStyles } from "./styles/global";

import {
  LandingScreen,
  AboutScreen,
  ContactScreen,
  FAQScreen,
  NewsScreen,
  HomeScreen,
  ArenaScreen,
  QuestsScreen,
} from "./screens";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Switch>
          <Route path="/" component={LandingScreen} exact />
          <Route path="/about" component={AboutScreen} exact />
          <Route path="/news" component={NewsScreen} exact />
          <Route path="/faq" component={FAQScreen} exact />
          <Route path="/contact" component={ContactScreen} exact />
          <Route path="/home" component={HomeScreen} exact />
          <Route path="/arena" component={ArenaScreen} exact />
          <Route path="/quests" component={QuestsScreen} exact />
        </Switch>
      </ThemeProvider>
    </Router>
  );
}

export default App;
