import LayoutPreface from "../../components/LayoutPreface";
import Typography from "../../components/Typography";
import ContactForm from "./ContactForm";

import { Container, Item } from "./styled";

function ContactScreen() {
  return (
    <LayoutPreface>
      <Typography variant="h1">Contact Us</Typography>
      <Container>
        <Item>
          <Typography variant="p">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magnam
            numquam impedit ducimus quia rerum mollitia nostrum qui eaque, iure
            ullam!
          </Typography>
        </Item>
        <Item>
          <ContactForm />
        </Item>
      </Container>
    </LayoutPreface>
  );
}

export default ContactScreen;
