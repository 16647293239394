import styled from "styled-components";

import CurrencyFrameImage from "../../../../assets/game/currency_frame.png";
import CurrencyChestImage from "../../../../assets/game/currency_chest.png";
import CurrencyDiamondImage from "../../../../assets/game/currency_diamond.png";
import CurrencyCoinImage from "../../../../assets/game/currency_coin.png";
import CurrencyBuyImage from "../../../../assets/game/currency_buy.png";

export const CurrencyFrameButton = styled.button`
  background-image: url(${CurrencyFrameImage});
  width: 93px;
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.1);
  }

  p {
    margin-top: 3px;
    font-size: 13px;
    line-height: 15px;
    color: #ffffff;
    font-weight: 700;
  }
`;

export const CurrencyImage = styled.img.attrs(({ type }) => ({
  src:
    type === "chest"
      ? CurrencyChestImage
      : type === "diamond"
      ? CurrencyDiamondImage
      : type === "coin"
      ? CurrencyCoinImage
      : type === "buy"
      ? CurrencyBuyImage
      : null,
  alt: type + " icon",
}))`
  width: 29px;
  height: 29px;
  margin-top: 6px;
`;
