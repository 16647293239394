import Header from "./Header";
import Footer from "./Footer";
import { Wrapper, Content } from "./styled";

function ScreenWrapper({ children }) {
  return (
    <Wrapper>
      <Header />
      <Content>{children}</Content>
      <Footer />
    </Wrapper>
  );
}

export default ScreenWrapper;
