import styled from "styled-components";

import CardImage from "../../../../assets/game/event_item_card.png";
import SwordsActiveImage from "../../../../assets/game/event_item_swordsactive.png";
import SwordsNotActiveImage from "../../../../assets/game/event_item_swordsnotactive.png";
import StreamLinkImage from "../../../../assets/game/event_item_streamicon.png";

export const Card = styled.div`
  background-image: url(${CardImage});
  width: 246px;
  height: 221px;
  padding: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BetButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BetButton = styled.button`
  width: 66px;
  height: 31px;
  padding-right: 10px;
  margin-left: 10px;
  clip-path: ${({ side }) =>
    side === "left"
      ? `polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%)`
      : `polygon(0% 50%, 20% 0%, 100% 0%, 100% 100%, 20% 100%)`};
  padding-right: ${({ side }) => (side === "left" ? "10px" : "0")};
  padding-left: ${({ side }) => (side === "right" ? "10px" : "0")};
  margin-right: ${({ side }) => (side === "right" ? "10px" : "0")};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.green : "#c69067"};
  color: #4f2232;
  font-size: 11px;
  font-weight: ${({ selected }) => (selected ? "800" : "700")};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
`;

export const TeamsLogos = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

export const TeamLogoContainer = styled.div`
  width: 80px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TeamLogo = styled.img`
  height: 57px;
  width: auto;
`;

export const TeamNames = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
`;

export const TeamName = styled.div`
  width: 90px;
  display: flex;
  justify-content: center;

  & > div {
    font-size: 8px;
    color: #4f2232;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
`;

export const GameTournamentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  position: relative;
`;

export const GameLogo = styled.img`
  width: 17px;
  height: 17px;
`;

export const GameName = styled.div`
  font-size: 7px;
  color: #4f2232;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 5px;
`;

export const TournamentName = styled.div`
  font-size: 8px;
  color: #4f2232;
  font-weight: 700;
  margin-top: 5px;
`;

export const TournamentDate = styled.div`
  font-size: 8px;
  color: #4f2232;
  font-weight: 700;
  margin-top: 2px;
`;

export const StreamLink = styled.a`
  position: absolute;
  top: 10px;
  right: 25px;
`;
export const StreamIcon = styled.img.attrs({
  src: StreamLinkImage,
})`
  height: 25px;
  width: 25px;
`;

export const SwordsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwordsImage = styled.img.attrs((props) => ({
  src: props.active ? SwordsActiveImage : SwordsNotActiveImage,
}))`
  width: 44px;
  height: 43px;
`;
