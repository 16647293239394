import { useDispatch } from "react-redux";

import { slipRemoveEvent } from "../../../../store/slip/actions";

import {
  AmountButton,
  BetButton,
  Body,
  BodyTextWrapper,
  Card,
  CurrencyAmount,
  CurrencyCurrentAmount,
  CurrencyType,
  CurrencyActionsWrapper,
  Footer,
  GameLogo,
  Header,
  HeaderContent,
  HeaderTextWrapper,
  RemoveButton,
  Result,
  SelectedTeam,
  Subtitle,
  Title,
  WinAmountText,
  WinAmountWrapper,
  WinCurrency,
  CurrencyTypeButton,
  CurrencyTypeImage,
  CurrencyTypeArrowImage,
} from "./styled";

import NoImage from "../../../../assets/game/no_image.png";

function SlipItem({ eventDto, teamNumber }) {
  const dispatch = useDispatch();

  const gameLogoUrl = eventDto.categoryLogoUrl;

  const title = `${eventDto.team1Name} vs ${eventDto.team2Name}`;

  const subTitle = `${eventDto.tournamentName}  -  ${eventDto.startDateFormatted}`;

  const selectedTeamName =
    teamNumber === 1 ? eventDto.team1Name : eventDto.team2Name;

  const coef = teamNumber === 1 ? eventDto.team1Winner : eventDto.team2Winner;

  const handleRemoveEvent = (eventId) => {
    dispatch(slipRemoveEvent(eventId));
  };

  return (
    <Card>
      <Header>
        <HeaderContent>
          <GameLogo src={gameLogoUrl || NoImage} />
          <HeaderTextWrapper>
            <Title>{title}</Title>
            <Subtitle>{subTitle}</Subtitle>
          </HeaderTextWrapper>
        </HeaderContent>
        <RemoveButton onClick={() => handleRemoveEvent(eventDto.id)}>
          &#x2715;
        </RemoveButton>
      </Header>
      <Body>
        <BodyTextWrapper>
          <Result>Match result</Result>
          <SelectedTeam>{selectedTeamName}</SelectedTeam>
        </BodyTextWrapper>
        <BetButton>{coef}</BetButton>
      </Body>
      <Footer>
        <CurrencyActionsWrapper>
          <CurrencyType>
            <CurrencyTypeButton>
              <CurrencyTypeImage type="diamond" />
              <CurrencyTypeArrowImage />
            </CurrencyTypeButton>
          </CurrencyType>
          <CurrencyAmount>
            <AmountButton>&#8722;</AmountButton>
            <CurrencyCurrentAmount>-</CurrencyCurrentAmount>
            <AmountButton>&#x2B;</AmountButton>
          </CurrencyAmount>
        </CurrencyActionsWrapper>
        <WinAmountWrapper>
          <WinAmountText>You can win</WinAmountText>
          <WinCurrency>
            <CurrencyTypeImage type="diamond" />
            <span>n/a</span>
          </WinCurrency>
        </WinAmountWrapper>
      </Footer>
    </Card>
  );
}

export default SlipItem;
