import { useSelector, useDispatch } from "react-redux";

import {
  dialogCleanYourPicks,
  // slipClean,
  slipUpdateStake,
} from "../../../../store/slip/actions";

import {
  Actions,
  CancelButton,
  ConfirmButton,
  FirstRow,
  // IconChest,
  IconDiamond,
  Input,
  SecondRow,
  SummaryWrapper,
  Table,
  ThirdRow,
} from "./styled";

function SlipSummary() {
  const dispatch = useDispatch();

  const slip = useSelector((state) => state.slip.slip);
  const stake = useSelector((state) => state.slip.stake);
  const winning = useSelector((state) => state.slip.winning);
  // const dialogCleanPicks = useSelector(
  //   (state) => state.slip.dialogCleanYourPicks
  // );

  const handleReset = () => {
    dispatch(dialogCleanYourPicks(true));
  };

  // const dialogCleanYourPicks_clear = () => {
  //   dispatch(slipClean());
  //   dispatch(dialogCleanYourPicks(false));
  // };

  // const dialogCleanYourPicks_cancel = () => {
  //   dispatch(dialogCleanYourPicks(false));
  // };

  return (
    <SummaryWrapper>
      <Table>
        <tbody>
          <FirstRow>
            <td>Total Stake:</td>
            {/* <td>
              <div>
                <IconChest />
                <span>31</span>
              </div>
            </td> */}
            <td>
              {/* <div>
                <IconDiamond />
                <span>11</span>
              </div> */}
              <Input
                value={stake}
                placeholder="Stake"
                min="0"
                type="number"
                onChange={(e) => dispatch(slipUpdateStake(e.target.value))}
              />
            </td>
          </FirstRow>
          <SecondRow>
            <td>
              <div>Possible Winnings:</div>
              {/* <div>Bonus! (bet 2 Dota games) x2</div> */}
            </td>
            {/* <td>
              <div>
                <IconChest />
                <span>35,22</span>
              </div>
            </td> */}
            <td>
              <div>
                <IconDiamond />
                <span>
                  {slip.length > 0 && stake ? winning.toFixed(2) : "-"}
                </span>
              </div>
            </td>
          </SecondRow>
          <ThirdRow>
            {/* <td>Total Possible Winnings:</td>
            <td>
              <div>
                <IconChest />
                <span>25,23</span>
              </div>
            </td>
            <td>
              <div>
                <IconDiamond />
                <span>55,22</span>
              </div>
            </td> */}
          </ThirdRow>
        </tbody>
      </Table>
      <Actions>
        <ConfirmButton>Lets Go!</ConfirmButton>
        <CancelButton onClick={handleReset}>Reset</CancelButton>
      </Actions>
    </SummaryWrapper>
  );
}

export default SlipSummary;
