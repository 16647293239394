import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  max-width: 1920px;
  margin: auto;

  // GPU Friendly Fixed Position Background Image
  overflow-y: hidden;
  position: relative;
  &::before {
    content: " ";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${({ background }) =>
      `url(${background})  no-repeat center center`};
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    will-change: transform; // creates a new paint layer
    z-index: -1;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 88px;
  padding-right: 50px;
  margin-top: auto;
  flex-grow: 1;

  @media (max-width: 1310px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;
