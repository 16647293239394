import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactGA from 'react-ga';

import store from "./store/store";
import App from "./App";

const TRACKING_ID = "G-ZPNPP9Y5FJ"; 
ReactGA.initialize(TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
