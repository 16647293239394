import styled from "styled-components";

export const ModalRegisterContainer = styled.div`
  display: flex;
`;

export const RegisterSection = styled.section`
  flex: 1;
  margin: 0 30px;

  h1 {
    text-align: center;
  }
`;

export const ChooseCharacterSection = styled.section`
  flex: 1;
  margin: 0 30px;

  h1 {
    text-align: center;
  }
`;

export const RegisterForm = styled.form`
  width: 100%;
`;

export const Group = styled.div`
  margin: ${({ variant }) => (variant === "inline" ? "30px 0" : "10px 0")};

  display: ${({ variant }) => (variant === "inline" ? "flex" : "block")};
  align-items: ${({ variant }) =>
    variant === "inline" ? "center" : "flex-start"};
`;

export const Label = styled.h3`
  font-size: 14px;
  font-weight: 700;
  line-height: 2;
  flex-shrink: 0;
  margin-right: auto;
`;

export const StyledInput = styled.input`
  font-size: 16px;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.lightBrown};
  border: 3px solid ${({ theme }) => theme.colors.mediumBrown};
  border-radius: 5px;
  outline: none;
  display: ${({ variant }) => (variant === "inline" ? "inline" : "block")};
  margin-left: ${({ variant }) => (variant === "inline" ? "10px" : "0")};
`;

export const TermsText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightBrown};
  font-weight: 500;
  line-height: 1.5;
  padding: 15px 10px;
  text-align: center;
`;

export const AlreadyHaveAccount = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  justify-content: center;
  margin-top: 45px;

  button {
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.lightBrown};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
  }
`;
