import styled from "styled-components";
import { NavLink as NavLinkInitial } from "react-router-dom";

export const Footer = styled.footer`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 10;
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem auto;
  padding: 0.7rem 0rem;
  z-index: 1;

  @media (max-width: 1000px) {
    align-items: flex-start;
    flex-direction: column;
  }

  @media (max-width: 600px) {
    padding: 2rem 0rem;
  }
`;

export const NavLink = styled(NavLinkInitial)`
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 1px;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid white;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;

  li + li {
    margin-left: 1rem;

    @media (max-width: 600px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &:first-child {
    @media (max-width: 1000px) {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
