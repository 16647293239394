import styled from "styled-components";

export const ButtonWrapper = styled.article`
  height: 60px;
  width: 100%;
  position: relative;
`;

export const ButtonImage = styled.img`
  position: absolute;
  width: 100%;

  left: 0;
  height: 100%;
`;

export const Button = styled.button`
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
  color: #fff;
  border: none;
  outline: none;
  font-weight: bold;
  font-size: 15px;
  text-transform: uppercase;
  padding-top: 2px;
  background-color: transparent;
`;
