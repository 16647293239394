import styled from "styled-components";

import CurrencyChestImage from "../../../../assets/game/currency_chest.png";
import CurrencyDiamondImage from "../../../../assets/game/currency_diamond.png";

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 75px 0;
`;

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  color: white;
  font-weight: 600;
  line-height: 26px;

  & td:first-of-type {
    width: 140px;
  }

  & td:nth-of-type(2),
  & td:nth-of-type(3) {
    width: 100%;
    font-size: 9px;
    font-weight: 600;

    & > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const FirstRow = styled.tr`
  & td:first-of-type {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const Input = styled.input`
  width: 110px;
  padding: 3px;
  font-size: 15px;
  margin-bottom: 3px;
  outline: none;
  color: white;
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const SecondRow = styled.tr`
  line-height: 12px;
  & td:first-of-type {
    font-size: 9px;
    font-weight: 600;

    & div:nth-of-type(2) {
      font-size: 7px;
    }
  }
`;

export const ThirdRow = styled.tr`
  & td:first-of-type {
    font-size: 9px;
  }
`;

export const IconChest = styled.span`
  display: inline-block;
  background-image: url(${CurrencyChestImage});
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 3px;
`;

export const IconDiamond = styled.span`
  display: inline-block;
  background-image: url(${CurrencyDiamondImage});
  background-size: cover;
  width: 20px;
  height: 20px;
  margin-right: 3px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const ConfirmButton = styled.button`
  width: 97px;
  height: 37px;
  background-color: #f5cb6d;
  border-radius: 18px;
  font-size: 15px;
  font-weight: 700;
  color: white;
`;
export const CancelButton = styled.button`
  background-color: transparent;
  font-size: 12px;
  height: 20px;
  width: 60px;
  margin-top: 10px;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
`;
