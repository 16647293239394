import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 2;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: 700;

  @media (max-width: 1000px) {
  }

  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const H2 = styled.h2`
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 42px;
  margin-bottom: 14px;
  color: #ffffff;
  font-weight: 600;
`;

export const Paragraph = styled.p`
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 2;
  margin-bottom: 14px;
  color: #ffffff;
  font-weight: 400;
`;
