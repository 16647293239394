import styled from "styled-components";

import SlipHeaderImage from "../../../assets/game/slip_board_top.png";
import SlipBodyImage from "../../../assets/game/slip_board_body.png";

export const SlipBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Header = styled.div`
  position: relative;
  top: 0;
  z-index: 1;
  height: 141px;
`;

export const HeaderBackground = styled.img.attrs({
  src: SlipHeaderImage,
  alt: "Slip header background",
})`
  width: 411px;
  height: 137px;
`;

export const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 100px 65px 0 65px;
`;

export const HeaderButton = styled.button`
  height: 21px;
  min-width: 106px;
  padding: 0 5px;
  border-radius: 11px;
  background-color: rgba(255, 138, 138, 0.55);
  color: ${({ theme }) => theme.colors.darkBrown};
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const SlipBody = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -40px;
`;

export const SlipBodyBackground = styled.img.attrs({
  src: SlipBodyImage,
  alt: "Slip body background",
})`
  width: 317px;
  height: 823px;
`;

export const SlipBodyContent = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

export const SlipItems = styled.div`
  height: 467px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 40px;
  padding-bottom: 15px;
  margin: 0px 25px 10px 80px;

  /* Custom Scrollbar */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    margin-top: 40px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: none;
  }
`;
