import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const MenuList = styled.ul`
  padding: 50px;
  width: 500px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LinkText = styled.span`
  font-size: 30px;
  line-height: 60px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ddd;
  transition: all 0.5s;

  &:hover {
    font-size: 32px;
    color: #fff;
  }
`;
