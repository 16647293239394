import styled from "styled-components";

export const Container = styled.div`
  margin: auto;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;
