import { useState } from "react";

import ModalRegister from "../ModalRegister";
import ModalLogin from "../ModalLogin";
import Modal from "../../Modal";

import {
  Header,
  Content,
  Navigation,
  NavLink,
  MenuBar,
  MenuIcon,
} from "./styled";

import FacebookImage from "../../../assets/preface/icon_facebook.png";
import InstagramImage from "../../../assets/preface/icon_instagram.png";

function StartScreenHeader() {
  const [hamMenuOpen, setHamMenuOpen] = useState(false);
  const [modalRegisterIsOpen, setModalRegisterIsOpen] = useState(false);
  const [modalLoginIsOpen, setModalLoginIsOpen] = useState(false);

  const handleHamMenu = () => {
    setHamMenuOpen(!hamMenuOpen);
  };

  return (
    <Header>
      <Content>
        <MenuBar onClick={handleHamMenu}>
          <MenuIcon isOpen={hamMenuOpen} />
        </MenuBar>
        <Navigation isOpen={hamMenuOpen}>
          <li>
            <NavLink to="/" exact activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/news" exact activeClassName="active">
              News
            </NavLink>
          </li>
          <li>
            <NavLink to="/about" exact activeClassName="active">
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" exact activeClassName="active">
              Contact
            </NavLink>
          </li>
        </Navigation>
        <Navigation isOpen={hamMenuOpen}>
          <li>
            <button onClick={() => setModalRegisterIsOpen(true)}>
              Sign up
            </button>
            <Modal
              variant="large"
              isOpen={modalRegisterIsOpen}
              setIsOpen={setModalRegisterIsOpen}
            >
              <ModalRegister
                setModalRegisterIsOpen={setModalRegisterIsOpen}
                setModalLoginIsOpen={setModalLoginIsOpen}
              />
            </Modal>
          </li>
          <li>
            <button onClick={() => setModalLoginIsOpen(true)}>Log in</button>
            <Modal
              variant="small"
              isOpen={modalLoginIsOpen}
              setIsOpen={setModalLoginIsOpen}
            >
              <ModalLogin
                setModalRegisterIsOpen={setModalRegisterIsOpen}
                setModalLoginIsOpen={setModalLoginIsOpen}
              />
            </Modal>
          </li>
          <li>
            <button>
              <img src={FacebookImage} alt="" />
            </button>
          </li>
          <li>
            <button>
              <img src={InstagramImage} alt="" />
            </button>
          </li>
        </Navigation>
      </Content>
    </Header>
  );
}

export default StartScreenHeader;
