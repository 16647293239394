import { Form, Group, Label, EmailInput, Select, Textarea } from "./styled";

function ContactForm() {
  return (
    <Form>
      <Group>
        <Label htmlFor="email">Email</Label>
        <EmailInput type="email" placeholder="Email" name="email" required />
      </Group>
      <Group>
        <Label htmlFor="topics">Choose a topic</Label>
        <Select name="topics" required>
          <option value=""></option>
          <option value="topic1">Topic 1</option>
          <option value="topic2">Topic 2</option>
        </Select>
      </Group>
      <Group>
        <Label>Message</Label>
        <Textarea name="message" id="" cols="30" rows="5" required></Textarea>
      </Group>
    </Form>
  );
}

export default ContactForm;
