import { Link } from "react-router-dom";

import Header from "../../components/LayoutPreface/Header";
import Footer from "../../components/LayoutPreface/Footer";

import "./style.css";

import BackgroundHeroImage from "../../assets/preface/background_top.png";
import BackgroundYellowImage from "../../assets/preface/background_yellow.png";
import LogoBigImage from "../../assets/preface/logo_big.png";
import LogoSmallImage from "../../assets/preface/logo_small.png";
import ButtonRectangleImage from "../../assets/preface/button_shape_rectangle.png";
import ButtonArrowDownImage from "../../assets/preface/button_arrow_down.png";
import IconQuestImage from "../../assets/preface/icon_quest.png";
import IconShopImage from "../../assets/preface/icon_shop.png";
import IconTrophiesImage from "../../assets/preface/icon_trophies.png";

function LandingScreen() {
  return (
    <div className="main-container">
      <div className="hero">
        <img src={BackgroundHeroImage} alt="" className="background-img" />
        <Header />
        <div className="hero-body">
          <img src={LogoBigImage} alt="" />
          <div className="hero-body-bottom">
            <div className="button-wrapper">
              <img src={ButtonRectangleImage} alt="" />
              <Link to="/home">
                <button>PLAY FOR FREE</button>
              </Link>
            </div>
            <a href="#information-banner" className="hero-arrow-icon">
              <img src={ButtonArrowDownImage} alt="" />
            </a>
          </div>
        </div>
        <div className="banner">
          <img src={BackgroundYellowImage} alt="" />
          <div className="banner-presentation">
            <h1>Enter the world of betquest</h1>
            <p>
              We provide a safe and legal betting experience with an extra
              questing twist. With a wide variety of games like League of
              Legends, Counter-Strike: Global Offensive, Dota 2 and more,
              there’s always something new to do
            </p>
          </div>
        </div>
      </div>

      <section className="information-section" id="information-banner">
        <div className="information-container-wrapper">
          <div className="information-container">
            <img src={IconQuestImage} alt="" />
            <div className="information-presentation">
              <h1>QUESTS</h1>
              <h4>Complete quests and earn experience points.</h4>
              <p>
                Browse daily and event specific quests to level up and get a
                step closer to becoming the master of the arena!
              </p>
            </div>
          </div>

          <div className="information-container">
            <div className="information-presentation">
              <h1>SHOP</h1>
              <h4>Earn medals and exchange for prizes!</h4>
              <p>
                In our in-game shop you can use youre earned medals to unlock
                cosmetics, why live under a rock when you can live in a castle?
              </p>
            </div>
            <img src={IconShopImage} alt="" />
          </div>

          <div className="information-container">
            <img src={IconTrophiesImage} alt="" />
            <div className="information-presentation">
              <h1>COMPETE</h1>

              <p>
                Join our ladders to compete for monthly and weekly prizes, show
                just why you are worthy of being titled The master of the arena!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="checkout-banner">
        <img src={BackgroundYellowImage} alt="" className="last-banner-img" />
        <section className="checkout-banner-content">
          <div className="started-betquest">
            <h1>GET STARTED WITH BETQUEST</h1>
            <div className="register-form">
              <div className="input-wrapper">
                <input type="email" placeholder="Email" />
              </div>
              <div className="register-button-wrapper">
                <button>REGISTER</button>
              </div>
            </div>
          </div>
          <div className="checkout-area">
            <h1>CHECKOUT THE AREA</h1>

            <div className="checkbox-button-wrapper">
              <button>VIEW NOW</button>
            </div>
          </div>
        </section>
      </section>

      <section className="footer-area">
        <div className="footer-main">
          <div className="back-glass-cover"></div>
          <div className="footer-main-content">
            <div className="latest-news">
              <h1>LATEST NEWS</h1>
              <div className="latest-news-reel">
                <div className="pure-news"></div>
                <div className="pure-news"></div>
                <div className="pure-news"></div>
              </div>
            </div>
            <div className="footer-main-content-presentation">
              <img src={LogoSmallImage} alt="" />
              <div className="button-wrapper">
                <img src={ButtonRectangleImage} alt="" />
                <Link to="/home">
                  <button>PLAY FOR FREE</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </section>
    </div>
  );
}

export default LandingScreen;
