import LayoutPreface from "../../components/LayoutPreface";
import Typography from "../../components/Typography";

function NewsScreen() {
  return (
    <LayoutPreface>
      <Typography variant="h1">News</Typography>
      <Typography variant="p">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Temporibus
        quas excepturi alias porro eaque, consequatur obcaecati distinctio
        repudiandae optio repellendus tenetur fugiat beatae, similique facere,
        harum nam aspernatur ullam! Alias provident quis veritatis dolores quam.
        Mollitia quam, voluptates, in explicabo tempora odit consectetur, rerum
        neque voluptatem inventore aspernatur sint error sapiente at
        consequuntur exercitationem cupiditate aut nam. Facere alias aliquid
        molestiae nulla labore, quasi a eligendi illo nisi nihil delectus
        laboriosam qui consectetur obcaecati repudiandae amet ipsum. Porro iure
        aperiam vero natus quae! Fuga architecto laboriosam similique quaerat
        nam, aspernatur vitae? Rerum eum ex, cumque ipsum eligendi facilis
        dolores libero facere impedit, ut eveniet, numquam cum? Facere ullam
        repellat nesciunt ea laborum odio animi quidem, quo possimus quia
        recusandae minima enim eligendi placeat velit voluptatem sed nulla vero
        saepe reiciendis quisquam vitae. Facilis nihil expedita harum
        repudiandae, ea molestias sit quidem minima sequi at obcaecati
        dignissimos, similique temporibus. Dolorem, labore?
      </Typography>
      <Typography variant="p">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quae aperiam
        at alias, laudantium porro atque! Ut reprehenderit quisquam nulla
        distinctio odit amet deserunt, pariatur similique, quibusdam corrupti
        natus accusamus quia? Error nam pariatur repudiandae. Odit repellendus
        eveniet quaerat qui. Obcaecati, provident ad repellendus veritatis modi
        quis. Consequatur, animi voluptates unde, nihil aliquid velit numquam
        autem recusandae porro fuga quia perspiciatis quas! Quaerat magnam
        numquam nemo labore. Explicabo consequatur et a repudiandae pariatur
        molestias ipsum facere voluptates sint corporis laboriosam quasi nulla
        id quod recusandae nobis cumque, repellat non dignissimos reprehenderit
        inventore commodi sequi natus rem. Quae consequuntur laboriosam non
        totam?
      </Typography>
    </LayoutPreface>
  );
}

export default NewsScreen;
