import { useSelector } from "react-redux";

import SlipItem from "./SlipItem";
import SlipSummary from "./SlipSummary";

import {
  SlipBoardWrapper,
  Header,
  SlipBody,
  HeaderButton,
  HeaderBackground,
  HeaderContent,
  SlipBodyBackground,
  SlipBodyContent,
  SlipItems,
} from "./styled";

function Slip() {
  const slipList = useSelector((state) => state.slip.slip);
  const events = useSelector((state) => state.events.list);

  const slipListOfEventDto = slipList.map((slipItem) =>
    events.find((eventDto) => eventDto.id === slipItem.eventId)
  );

  return (
    <SlipBoardWrapper>
      <Header>
        <HeaderBackground />
        <HeaderContent>
          <HeaderButton>Single Pick</HeaderButton>
          <HeaderButton>Multi Pick</HeaderButton>
        </HeaderContent>
      </Header>
      <SlipBody>
        <SlipBodyBackground />
        <SlipBodyContent>
          <SlipItems>
            {slipListOfEventDto &&
              slipListOfEventDto.map((eventDto2, index) => (
                <SlipItem
                  key={eventDto2.id}
                  eventDto={eventDto2}
                  teamNumber={slipList[index].teamNumber}
                />
              ))}
          </SlipItems>
          <SlipSummary />
        </SlipBodyContent>
      </SlipBody>
    </SlipBoardWrapper>
  );
}

export default Slip;
