import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import CurrencyFrame from "./CurrencyFrame";

import {
  HeaderWrapper,
  Avatar,
  CharacterWrapper,
  Character,
  TitleWrapper,
  TitleContent,
  CurrencyList,
  HomeButton,
  LeftMenuList,
  MenuSection,
  QuestsButton,
  RightMenuWrapper,
} from "./styled";

function Header({ title }) {
  const location = useLocation();

  const accountBalance = useSelector((state) => state.slip.accountBalance);

  return (
    <HeaderWrapper>
      <TitleWrapper>
        <TitleContent title={title} />
      </TitleWrapper>
      <MenuSection>
        {location.pathname !== "/home" && (
          <LeftMenuList>
            <li>
              <Link to="/home">
                <HomeButton />
              </Link>
            </li>
            {location.pathname !== "/quests" && (
              <li>
                <Link to="/quests">
                  <QuestsButton />
                </Link>
              </li>
            )}
          </LeftMenuList>
        )}
        <RightMenuWrapper>
          <CurrencyList>
            <li>
              <CurrencyFrame type="coin" text={0} />
            </li>
            <li>
              <CurrencyFrame type="chest" text={0} />
            </li>
            <li>
              <CurrencyFrame type="diamond" text={accountBalance} />
            </li>
            <li>
              <CurrencyFrame type="buy" />
            </li>
          </CurrencyList>
          <Avatar>
            <CharacterWrapper>
              <Character />
            </CharacterWrapper>
          </Avatar>
        </RightMenuWrapper>
      </MenuSection>
    </HeaderWrapper>
  );
}

export default Header;
