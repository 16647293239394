import LayoutGame from "../../components/LayoutGame";
import Events from "./Events";
import Slip from "./Slip";

import { Container } from "./styled";

import BackgroundAmphiteaterImage from "../../assets/game/background_amphiteater.png";
import TitleArenaImage from "../../assets/game/title_arena.png";

function ArenaScreen() {
  return (
    <LayoutGame background={BackgroundAmphiteaterImage} title={TitleArenaImage}>
      <Container>
        <Events />
        <Slip />
      </Container>
    </LayoutGame>
  );
}

export default ArenaScreen;
