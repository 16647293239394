import LayoutPreface from "../../components/LayoutPreface";
import Typography from "../../components/Typography";

function FAQScreen() {
  return (
    <LayoutPreface>
      <Typography variant="h1">FAQ</Typography>
      <Typography variant="h2">Lorem ipsum dolor sit amet.</Typography>
      <Typography variant="p">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Labore
        perspiciatis dolorum temporibus corrupti quos modi recusandae, cumque
        iste quidem natus voluptas atque officiis ea asperiores autem commodi
        nostrum voluptate dignissimos nobis veritatis velit. Minima ex quas,
        laborum rerum dolore veniam velit quisquam voluptate voluptas ullam
        quasi sapiente, odio pariatur quos quod recusandae architecto illo dolor
        earum possimus. Tempore quasi labore molestias reiciendis porro
        adipisci, mollitia dolorum debitis ad omnis minus! Nostrum tempore natus
        numquam explicabo magnam ab sint adipisci quod doloribus, eos odio ad
        maxime qui alias reprehenderit optio molestias amet saepe neque nam!
        Fuga blanditiis nostrum ipsa earum optio.
      </Typography>
      <Typography variant="h2">Lorem ipsum dolor sit.</Typography>
      <Typography variant="p">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque autem
        itaque consectetur necessitatibus eaque, nulla reprehenderit temporibus
        sed iure eveniet aliquid quas, magni veniam laborum distinctio! Illo
        maiores pariatur est modi accusantium atque. Exercitationem
        reprehenderit eveniet, voluptas natus ea vitae possimus placeat quos
        quasi nostrum veritatis harum molestiae expedita delectus odio
        asperiores obcaecati fugiat. Odit laudantium tempore maxime! Perferendis
        obcaecati ipsum mollitia sed illum sit. Dolor quisquam ab veniam rerum
        perferendis in voluptate cumque, iste veritatis nemo excepturi
        repellendus quibusdam voluptatum iusto? Sequi quos neque nobis saepe
        tempore itaque in, voluptatum quisquam consequatur quam odit quasi,
        dolorem molestias dolorum? Tempore, ipsum sed. Incidunt non fugit
        voluptate officia doloremque sint saepe aut quae impedit, consequuntur
        nulla doloribus at nobis totam minus possimus quas beatae suscipit velit
        amet modi laboriosam eveniet tenetur autem! Sunt repudiandae quasi
        eligendi, exercitationem rerum perspiciatis adipisci ex voluptate. Omnis
        debitis, nulla recusandae est ipsa aut iusto harum.
      </Typography>
      <Typography variant="h2">
        Lorem ipsum dolor sit amet consectetur.
      </Typography>
      <Typography variant="p">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ullam
        inventore recusandae cumque minus rerum illo consequatur, eaque optio
        soluta magnam? Eveniet unde velit aperiam ab eius culpa? Consectetur ea
        optio a laudantium fuga natus ratione unde minima, repudiandae amet ad
        vitae at tempore id, eos ullam veritatis quisquam aliquam quam.
      </Typography>
    </LayoutPreface>
  );
}

export default FAQScreen;
