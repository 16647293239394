import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { eventsGet } from "../../../store/events/actions";

import EventItem from "./EventItem";

import {
  BoardBackgroundImage,
  EventBoardWrapper,
  EventFilters,
  EventListGrid,
  InnerWrapper,
  Menu,
  TabLarge,
  TabList,
  TabSmall,
} from "./styled";

function Events() {
  const dispatch = useDispatch();

  const events = useSelector((state) => state.events.list);
  const isLoading = useSelector((state) => state.events.loading);
  const errorMessage = useSelector((state) => state.events.errorMessage);

  const slip = useSelector((state) => state.slip.slip);

  const [tabActive, setTabActive] = useState(0);

  useEffect(() => {
    dispatch(eventsGet());
  }, [dispatch]);

  return (
    <EventBoardWrapper>
      <BoardBackgroundImage />
      <InnerWrapper>
        <Menu>
          <TabList>
            <TabLarge active={tabActive === 0} onClick={() => setTabActive(0)}>
              <span>Upcoming {events.length}</span>
            </TabLarge>
            <TabSmall active={tabActive === 1} onClick={() => setTabActive(1)}>
              <span>Live</span>
            </TabSmall>
            <TabSmall active={tabActive === 2} onClick={() => setTabActive(2)}>
              <span>Past</span>
            </TabSmall>
          </TabList>
          <EventFilters />
        </Menu>
        <EventListGrid>
          {isLoading && <h3 style={{ color: "white" }}>Loading...</h3>}
          {errorMessage && <h3 style={{ color: "red" }}>{errorMessage}</h3>}
          {events &&
            tabActive === 0 &&
            events.map((event, index) => (
              <EventItem
                key={event.id}
                eventDto={event}
                eventDtoIndex={index}
                inSlip={slip.find((slipItem) => slipItem.eventId === event.id)}
              />
            ))}
        </EventListGrid>
      </InnerWrapper>
    </EventBoardWrapper>
  );
}

export default Events;
