import {
  FooterWrapper,
  HelpButton,
  ChatButton,
  HelpAndChatList,
} from "./styled";

function Footer() {
  return (
    <FooterWrapper>
      <HelpAndChatList>
        <li>
          <HelpButton />
        </li>
        <li>
          <ChatButton />
        </li>
      </HelpAndChatList>
    </FooterWrapper>
  );
}

export default Footer;
