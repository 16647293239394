import Typography from "../../Typography";
import {
  ModalLoginContainer,
  LoginForm,
  Group,
  ForgotPassword,
  StyledInput,
  DontHaveAccount,
} from "./styled";

import ButtonYellow from "../../ButtonYellow";

function ModalLogin({ setModalRegisterIsOpen, setModalLoginIsOpen }) {
  const handleLogin = (e) => {
    e.preventDefault();
    setModalLoginIsOpen(false);
  };
  const handleDontHaveAccount = () => {
    setModalLoginIsOpen(false);
    setModalRegisterIsOpen(true);
  };
  return (
    <ModalLoginContainer>
      <Typography variant="h1">Login</Typography>
      <LoginForm>
        <Group>
          <StyledInput
            type="email"
            placeholder="Email"
            name="email"
            required
            autoComplete="new-password"
          />
        </Group>
        <Group>
          <StyledInput
            type="password"
            placeholder="Password"
            name="password"
            required
            autoComplete="new-password"
          />
        </Group>
        <ButtonYellow onClick={handleLogin}>Resume your adventure</ButtonYellow>
        <ForgotPassword>Forgot Password?</ForgotPassword>
      </LoginForm>
      <DontHaveAccount onClick={handleDontHaveAccount}>
        <p>If you don't have an account, sign up</p>
        <button> here.</button>
      </DontHaveAccount>
    </ModalLoginContainer>
  );
}

export default ModalLogin;
