import LayoutPreface from "../../components/LayoutPreface";
import Typography from "../../components/Typography";

function AboutScreen() {
  return (
    <LayoutPreface>
      <Typography variant="h1">About Us</Typography>
      <Typography variant="p">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi nostrum
        odit recusandae maiores quam accusamus dolores libero exercitationem nam
        corporis sed totam repellendus voluptatem obcaecati voluptate aperiam
        ratione officia, molestias assumenda sapiente expedita debitis amet
        praesentium rem. Vel provident dignissimos sed odit vero tempora
        officiis laboriosam ex obcaecati repellendus necessitatibus fugit
        similique fugiat dolor, voluptatum odio, cum quia accusantium! Quisquam
        tempore veniam non nemo nihil magnam deleniti accusantium harum quod
        excepturi reprehenderit laborum dolore voluptatum expedita, explicabo
        corporis dolorum dolorem aliquid eius distinctio omnis, vero nesciunt ab
        voluptates. Hic reprehenderit enim ea voluptatem perspiciatis aspernatur
        maiores natus ad ex deleniti.
      </Typography>
      <Typography variant="p">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nisi nostrum
        odit recusandae maiores quam accusamus dolores libero exercitationem nam
        corporis sed totam repellendus voluptatem obcaecati voluptate aperiam
        ratione officia, molestias assumenda sapiente expedita debitis amet
        praesentium rem. Vel provident dignissimos sed odit vero tempora
        officiis laboriosam ex obcaecati repellendus necessitatibus fugit
        similique fugiat dolor, voluptatum odio, cum quia accusantium! Quisquam
        tempore veniam non nemo nihil magnam deleniti accusantium harum quod
        excepturi reprehenderit laborum dolore voluptatum expedita, explicabo
        corporis dolorum dolorem aliquid eius distinctio omnis, vero nesciunt ab
        voluptates. Hic reprehenderit enim ea voluptatem perspiciatis aspernatur
        maiores natus ad ex deleniti.
      </Typography>
    </LayoutPreface>
  );
}

export default AboutScreen;
