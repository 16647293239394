import { H1, H2, Paragraph } from "./styled";

function Typography({ children, variant }) {
  let output;
  switch (variant) {
    case "h1":
      output = <H1>{children}</H1>;
      break;
    case "h2":
      output = <H2>{children}</H2>;
      break;
    case "p":
      output = <Paragraph>{children}</Paragraph>;
      break;
    default:
      output = <p>{children}</p>;
  }

  return output;
}

export default Typography;
