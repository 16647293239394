import { Link } from "react-router-dom";
import LayoutGame from "../../components/LayoutGame";

import { Container, MenuList, LinkText } from "./styled";

import BackgroundHomeImage from "../../assets/game/background_home.png";
import TitleHomeImage from "../../assets/game/title_home.png";

function HomeScreen() {
  return (
    <LayoutGame background={BackgroundHomeImage} title={TitleHomeImage}>
      <Container>
        <MenuList>
          <li>
            <Link to="/arena">
              <LinkText>Arena</LinkText>
            </Link>
          </li>
          <li>
            <Link to="/quests">
              <LinkText>Quests</LinkText>
            </Link>
          </li>
          <li>
            <Link to="/">
              <LinkText>Exit</LinkText>
            </Link>
          </li>
        </MenuList>
      </Container>
    </LayoutGame>
  );
}

export default HomeScreen;
