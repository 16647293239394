import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    height: 100vh;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.main};
    background-color: ${({ theme }) => theme.colors.main};
  }

  ul, li {
    list-style-type: none;
  }

  button {
    outline: none;
    border: none;
    background-color: transparent;
    font-family: ${({ theme }) => theme.fonts.main};
    cursor: pointer;
  }

  img {
    width: 100%;
  }

  /* Custom Scrollbar */
*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.main};
}

*::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme.colors.lightBrown};
  border: 1px solid ${({ theme }) => theme.colors.lightBrown};
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: ${({ theme }) => theme.colors.mediumBrown};
  border: 1px solid ${({ theme }) => theme.colors.mediumBrown};
}
`;
