import { EVENTS_GET, EVENTS_GET_SUCCESS, EVENTS_GET_FAILURE } from "./types";

const INITIAL_STATE = {
  list: [],
  errorMessage: undefined,
  loading: false,
};

export const eventsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EVENTS_GET:
      return { ...state, loading: true, errorMessage: undefined };
    case EVENTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
        errorMessage: undefined,
      };
    case EVENTS_GET_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage,
      };

    default:
      return state;
  }
};
