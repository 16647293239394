import styled from "styled-components";

export const Container = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const Item = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 100px;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;
