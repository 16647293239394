import styled from "styled-components";

import ButtonHomeImage from "../../../assets/game/button_home.png";
import ButtonQuestImage from "../../../assets/game/button_quest.png";
import AvatarFrameImage from "../../../assets/game/avatar_frame.png";
import CharacterImage from "../../../assets/game/character.png";

export const HeaderWrapper = styled.header`
  height: 156px;
  padding-left: 50px;
  padding-right: 72px;

  @media (max-width: 1310px) {
    padding-left: 20px;
    padding-right: 42px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TitleContent = styled.div`
  background-image: ${({ title }) => `url(${title})`};
  width: 295px;
  height: 125px;
`;

export const MenuSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: -125px;
  height: 100%;
`;

export const LeftMenuList = styled.ul`
  display: flex;
  align-items: center;
  flex: 1;

  li:first-child {
    margin: 0 30px 0 0px;
  }

  button {
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }
`;

export const HomeButton = styled.button`
  background-image: url(${ButtonHomeImage});
  width: 147px;
  height: 123px;
`;

export const QuestsButton = styled.button`
  background-image: url(${ButtonQuestImage});
  width: 98px;
  height: 91px;
`;

export const RightMenuWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const CurrencyList = styled.ul`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  li {
    margin: 0 8px;
  }
`;

export const Avatar = styled.div`
  margin-left: 45px;
  margin-top: 18px;
  transition: all 0.5s;

  &:hover {
    transform: scale(1.05);
  }
`;

export const CharacterWrapper = styled.div`
  background-image: url(${AvatarFrameImage});
  width: 147px;
  height: 137px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const Character = styled.div`
  background-image: url(${CharacterImage});
  width: 87px;
  height: 87px;
  margin-left: 5px;
  position: absolute;
  top: 36px;
  overflow: hidden;
`;
