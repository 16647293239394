import { ButtonWrapper, ButtonImage, Button } from "./styled";

import ButtonRectangleImage from "../../assets/preface/button_shape_rectangle.png";

function ButtonYellow({ children }) {
  return (
    <ButtonWrapper>
      <ButtonImage src={ButtonRectangleImage} alt="" />
      <Button>{children}</Button>
    </ButtonWrapper>
  );
}

export default ButtonYellow;
