import styled from "styled-components";

import CurrencyChestImage from "../../../../assets/game/currency_chest.png";
import CurrencyDiamondImage from "../../../../assets/game/currency_diamond.png";
import CurrencyCoinImage from "../../../../assets/game/currency_coin.png";

export const Card = styled.div`
  width: 253px;
  min-height: 159px;
  background-color: rgba(255, 138, 138, 0.25);
  border-radius: 20px;
  padding: 16px 8px 12px;
  margin-bottom: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const HeaderContent = styled.div`
  display: flex;
`;
export const GameLogo = styled.img`
  height: 25px;
  width: auto;
  margin-right: 8px;
`;
export const HeaderTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-size: 10px;
  font-weight: 500;
`;

export const Subtitle = styled.div`
  font-size: 9px;
  font-weight: 400;
`;

export const RemoveButton = styled.button`
  display: inline;
  font-size: 17px;
  height: 17px;
  margin-top: -3px;
  color: #5a0400;
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BodyTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Result = styled.div`
  font-size: 9px;
  font-weight: 400;
  text-transform: uppercase;
`;

export const SelectedTeam = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const BetButton = styled.button`
  width: 58px;
  height: 27px;
  background-color: #c69067;
  padding-right: 10px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  clip-path: polygon(0% 0%, 80% 0%, 100% 50%, 80% 100%, 0% 100%);
  cursor: default;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const CurrencyActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const CurrencyType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CurrencyTypeButton = styled.button`
  display: flex;
  align-items: center;
  margin-right: 7px;
`;

export const CurrencyTypeImage = styled.div`
  background-image: ${({ type }) =>
    `url(${
      type === "diamond"
        ? CurrencyDiamondImage
        : type === "chest"
        ? CurrencyChestImage
        : type === "coin"
        ? CurrencyCoinImage
        : ""
    })`};
  background-size: cover;
  width: 23px;
  height: 23px;
  margin-right: 4px;
`;

export const CurrencyTypeArrowImage = styled.div`
  width: 9px;
  height: 5px;
  background-color: #e0a05c;
  clip-path: polygon(0 0, 100% 0%, 50% 100%);
`;

export const CurrencyAmount = styled.div`
  width: 97px;
  height: 37px;
  background-color: #f5cb6d;
  border-radius: 18px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const AmountButton = styled.button`
  background-color: #eeb24b;
  color: #773e14;
  border-radius: 50px;
  width: 17px;
  height: 17px;
`;

export const CurrencyCurrentAmount = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #851404;
`;

export const WinAmountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-width: 80px;
`;

export const WinAmountText = styled.div`
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const WinCurrency = styled.div`
  display: flex;
  align-items: center;

  & > span {
    font-size: 12px;
    font-weight: 600;
  }
`;
