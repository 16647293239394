import styled from "styled-components";

export const ModalLoginContainer = styled.div`
  padding: 0 150px;

  h1 {
    text-align: center;
  }
`;

export const LoginForm = styled.form`
  width: 100%;
  div:last-of-type {
    margin-bottom: 30px;
  }
`;

export const Group = styled.div`
  margin: ${({ variant }) => (variant === "inline" ? "30px 0" : "10px 0")};

  display: ${({ variant }) => (variant === "inline" ? "flex" : "block")};
  align-items: ${({ variant }) =>
    variant === "inline" ? "center" : "flex-start"};
`;

export const StyledInput = styled.input`
  font-size: 16px;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.lightBrown};
  border: 3px solid ${({ theme }) => theme.colors.mediumBrown};
  border-radius: 5px;
  outline: none;
  display: ${({ variant }) => (variant === "inline" ? "inline" : "block")};
  margin-left: ${({ variant }) => (variant === "inline" ? "10px" : "0")};
`;

export const ForgotPassword = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightBrown};
  font-weight: 500;
  line-height: 1.5;
  margin: 15px 10px 35px;
  text-align: center;
  cursor: pointer;
`;

export const DontHaveAccount = styled.div`
  margin: 20px 50px 0;
  text-align: center;
  p {
    display: inline;
    font-size: 15px;
  }

  button {
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.lightBrown};
    font-weight: 600;
    font-size: 15px;
  }
`;
