import Header from "./Header";
import Footer from "./Footer";

import { Wrapper, Main } from "./styled";

function LayoutGame({ background, title, children }) {
  return (
    <Wrapper background={background}>
      <Header title={title} />
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  );
}

export default LayoutGame;
