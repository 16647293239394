import ReactDOM from "react-dom";

import {
  Overlay,
  ModalBox,
  ButtonModalClose,
  ModalBackgroundBody,
  ModalBackgroundTop,
  ModalBackgroundBottom,
  Content,
} from "./styled";

function Modal({ variant, isOpen, setIsOpen, children }) {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <Overlay isOpen={isOpen} onClick={() => setIsOpen(false)}>
      <ModalBox variant={variant} onClick={(e) => e.stopPropagation()}>
        <ModalBackgroundTop variant={variant} />
        <ModalBackgroundBody variant={variant} />
        <ButtonModalClose variant={variant} onClick={() => setIsOpen(false)} />
        <Content variant={variant}>{children}</Content>
        <ModalBackgroundBottom variant={variant} />
      </ModalBox>
    </Overlay>,
    document.getElementById("modal-root")
  );
}

export default Modal;
