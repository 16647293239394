import { Footer as FooterStyled, Content, Navigation, NavLink } from "./styled";

function Footer() {
  return (
    <FooterStyled>
      <Content>
        <Navigation>
          <li>
            <NavLink to="/" exact activeClassName="active">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/news" exact activeClassName="active">
              News
            </NavLink>
          </li>
        </Navigation>
        <Navigation>
          <li>
            <NavLink to="/about" exact activeClassName="active">
              About us
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact" exact activeClassName="active">
              Contact us
            </NavLink>
          </li>
          <li>
            <NavLink to="/faq" exact activeClassName="active">
              FAQ
            </NavLink>
          </li>
        </Navigation>
      </Content>
    </FooterStyled>
  );
}

export default Footer;
