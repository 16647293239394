import {
  SLIP_ADD_EVENT,
  SLIP_REMOVE_EVENT,
  SLIP_UPDATE_STAKE,
  SLIP_UPDATE_COEF,
  SLIP_DIALOG_CLEAN_YOUR_PICKS,
  SLIP_CLEAN,
} from "./types";

const INITIAL_STATE = {
  slip: [],
  accountBalance: 1076,
  stake: "",
  coef: 1,
  winning: 0,
  dialogClearYourPicks: false,
};

export const slipReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SLIP_ADD_EVENT:
      return { ...state, slip: [...state.slip, action.payload] };
    case SLIP_REMOVE_EVENT:
      return {
        ...state,
        slip: state.slip.filter(
          (element) => element.eventId !== action.payload
        ),
      };
    case SLIP_UPDATE_STAKE:
      return {
        ...state,
        stake: action.payload.stake,
        winning: action.payload.winning,
      };
    case SLIP_UPDATE_COEF:
      return {
        ...state,
        coef: action.payload.coef,
        winning: action.payload.winning,
      };
    case SLIP_CLEAN:
      return {
        ...state,
        slip: [],
        coef: 1,
        winning: 0,
      };
    case SLIP_DIALOG_CLEAN_YOUR_PICKS:
      return {
        ...state,
        dialogCleanYourPicks: action.payload,
      };
    default:
      return state;
  }
};
