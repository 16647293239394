import api from "../../apis/api";

import { EVENTS_GET, EVENTS_GET_SUCCESS, EVENTS_GET_FAILURE } from "./types";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    // year = d.getFullYear(),
    hour = "" + d.getHours(),
    minutes = "" + d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (minutes.length < 2) minutes = "0" + minutes;

  return `${day}.${month} ${hour}:${minutes}`;
}

//
// ACTION CREATOR eventsGet
//
export const eventsGet = () => async (dispatch) => {
  dispatch({ type: EVENTS_GET });
  try {
    const response = await api.get("/events/upcoming");
    if (response != null && response.status === 200) {
      const dataAdapted = response.data.map((el) => ({
        ...el,
        startDateFormatted: formatDate(new Date(Date.parse(el.startDate))),
      }));
      dispatch({
        type: EVENTS_GET_SUCCESS,
        payload: { list: dataAdapted },
      });
    } else {
      const errorMessage = `Error fetching events. Error code: ${
        response != null ? response.status : null
      }`;
      dispatch({
        type: EVENTS_GET_FAILURE,
        payload: { errorMessage },
      });
    }
  } catch (e) {
    const errorMessage = `Error fetching events. Exception: ${e.toString()}`;
    dispatch({
      type: EVENTS_GET_FAILURE,
      payload: { errorMessage },
    });
  }
};
