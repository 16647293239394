import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
`;

export const Group = styled.div`
  margin-bottom: 10px;
`;

export const Label = styled.h3`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lightBrown};
  font-weight: 500;
  line-height: 2;
`;

export const EmailInput = styled.input`
  font-size: 16px;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.lightBrown};
  border: 3px solid ${({ theme }) => theme.colors.mediumBrown};
  border-radius: 5px;
  outline: none;
`;

export const Select = styled.select`
  font-size: 16px;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.lightBrown};
  border: 3px solid ${({ theme }) => theme.colors.mediumBrown};
  border-radius: 5px;
  outline: none;
`;

export const Textarea = styled.textarea`
  font-size: 16px;
  width: 100%;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.lightBrown};
  border: 3px solid ${({ theme }) => theme.colors.mediumBrown};
  border-radius: 5px;
  outline: none;
`;
