import LayoutGame from "../../components/LayoutGame";

import { Container } from "./styled";

import BackgroundQuestsImage from "../../assets/game/background_quests.png";
import TitleQuestsImage from "../../assets/game/title_quests.png";

function QuestsScreen() {
  return (
    <LayoutGame background={BackgroundQuestsImage} title={TitleQuestsImage}>
      <Container>
        <h1>No Quests</h1>
      </Container>
    </LayoutGame>
  );
}

export default QuestsScreen;
