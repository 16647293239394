import { CurrencyFrameButton, CurrencyImage } from "./styled";

function CurrencyFrame({ type, text }) {
  return (
    <CurrencyFrameButton>
      <CurrencyImage type={type} />
      <p>{text}</p>
    </CurrencyFrameButton>
  );
}

export default CurrencyFrame;
