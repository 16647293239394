import axios from "axios";

export default axios.create({
  baseURL: "https://nomaths.cryptosoft.hr/api",
  headers: { ApiKey: "h5CjZ9T91oxRzrxpEqb3" },
//  baseURL: process.env.REACT_APP_API_ENDPOINT,
//  headers: { ApiKey: process.env.REACT_APP_API_KEY },
});

export const SportsbookApi = axios.create({
  baseURL: "https://localhost:44365/api"
});
