export const theme = {
  colors: {
    main: "#1f1a23",
    red: "#de7162",
    green: "#a0c47c",
    darkBrown: "#422326",
    mediumBrown: "#b37d62",
    lightBrown: "#e0b98a",
  },
  fonts: {
    main: "Montserrat, sans-serif",
  },
};
