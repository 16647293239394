import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  background-color: #1f1a23;
`;

export const Content = styled.div`
  padding-top: 150px;
  padding-bottom: 81px;
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1000px) {
    padding-bottom: 111px;
    width: 80%;
  }

  @media (max-width: 600px) {
    padding-top: 80px;
    padding-bottom: 250px;
    width: 90%;
  }
`;
