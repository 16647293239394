import styled from "styled-components";
import { NavLink as NavLinkInitial } from "react-router-dom";

import HamButtonImage from "../../../assets/preface/button_hamburger.png";
import CancelButtonImage from "../../../assets/preface/button_cancel.png";

export const Header = styled.header`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  z-index: 10;

  @media (max-width: 600px) {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
  }
`;

export const NavLink = styled(NavLinkInitial)`
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 1px;
  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid white;
  }
`;

export const Content = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem auto;
  padding: 0.7rem 0rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-start;
  }

  button {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
  }

  img {
    width: 30px;
  }

  li + li {
    margin-left: 1rem;

    @media (max-width: 600px) {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;

export const MenuBar = styled.div`
  display: none;

  @media (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const MenuIcon = styled.span`
  background-image: ${({ isOpen }) =>
    isOpen ? `url(${CancelButtonImage})` : `url(${HamButtonImage})`};
  background-size: cover;
  width: 30px;
  height: 30px;
  cursor: pointer;
  fill: white;
`;
