import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  margin-top: auto;
`;
